import Layout from '../components/Layout';
import Carousel from '../components/Carousel/Carousel';
import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import '../styles/main.css';

type Props = PageProps<any>;

const Index: FC<Props> = ({ data }: Props) => {
  const homeGallery = data.contentfulGallery?.gallery;
  return (
    <Layout>
      <Carousel gallery={homeGallery} />
    </Layout>
  );
};

export const query = graphql`
  query IndexPage {
    contentfulGallery(category: { eq: "Home" }) {
      gallery {
        full: gatsbyImageData(
          height: 1334
          width: 2000
          placeholder: BLURRED
          outputPixelDensities: [0.25, 0.5, 1]
        )
        title
      }
    }
  }
`;

export default Index;
